:root {
  --bg-color: #e5e5e5;
  --header-color: #ffffff;
  --text-color: #000000;
  --text-color-secondary: #666666;
  --todo-bg-color: #ffffff;
  --border-color: #93a9ff;
  --button-color: #93a9ff;
  --svg-filter: invert(83%) sepia(49%) saturate(1%) hue-rotate(346deg) brightness(100%) contrast(104%);
}

:root.darkmode {
  --bg-color: #181824;
  --header-color: #ffffff;
  --text-color: #ffffff;
  --text-color-secondary: #999999;
  --todo-bg-color: #25273c;
  --border-color: #93a9ff;
  --button-color: #93a9ff;
  --svg-filter: invert(2%) sepia(2%) saturate(772%) hue-rotate(315deg) brightness(98%) contrast(90%);
}

@font-face {
  font-family: 'Kanit';
  src: url('./assets/fonts/Kanit-Regular.ttf') format('ttf');
}

svg {
  fill: #000000;
}

body {
  background-color: var(--bg-color);
  background-image: url('./assets/background.png');
  background-repeat: no-repeat;
  background-size: 100vw 25vh;
  background-position: center top;
}

.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  width: 600px;
  margin: 5vh auto;
}

.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  font-size: calc(10px + 2vmin);
  color: var(--header-color);
}

.title {
  font-family: 'Kanit';
  font-style: normal;
  font-weight: 500;
  font-size: 48px;
  line-height: 72px;
}

.toggle {
  background: transparent;
  border: none;
}

.background {
  background: var(--todo-bg-color);
  box-shadow: 0px 4px 64px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
}

.addTodo {
  background: var(--todo-bg-color);
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
  border-radius: 8px 8px 0px 0px;
  padding: 1em;
}

.inputField {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  border: 2px solid var(--border-color);
  border-radius: 100vh;
  heigth: 10px;
}

.input {
  box-sizing: border-box;
  width: 100%;
  border: 0;
  padding: 0.5em;
  outline: none;
  margin: 0 1em;
  background: var(--todo-bg-color);
  color: var(--text-color);
}

.button {
  border: 2px solid var(--border-color);
  background: var(--button-color);
  border-radius: 100vh;
  width: 100px;
  padding: 5px;
}

.todoList {
  background: var(--todo-bg-color);
  width: 100%;
  min-height: 400px;
}

.emptyTodo {
  background: var(--todo-bg-color);
  width: 100%;
  height: 400px;

  padding-top: 10%;
  color: var(--text-color);
}

.emptyTodo > img {
  filter: var(--svg-filter);
}

.todoLabel {
  display: flex;
  align-items: center;
}

.todoLabel > input {
  margin-right: 0.5em;
}

.todoTextCompleted {
  text-decoration: line-through;
  color: var(--text-color-secondary);
}

.todo {
  display: inline-block;
  border: 1px solid var(--text-color-secondary);
  border-radius: 8px;
  margin: 1em;
  padding: 1em;
  text-align: left;
  vertical-align: middle;
  color: var(--text-color);

  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 95%;
  height: 54px;
  font-size: 1em;
  background-color: var(--todo-bg-color);
}

.todo:hover,
.todo:focus {
  opacity: 0.6;
}

.deleteTodo {
  display: none;
  filter: var(--svg-filter);
  width: 24px;
  height: 24px;
  background: transparent;
  border: none;
}

.deleteTodo:hover {
  opacity: 0.6;
}

.footer {
  color: var(--text-color);
  text-align: right;
  margin: 1em;
}
